<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";

/**
 * Starter page component
 */
export default {
  page() {
    return {
      title: this.title,
      meta: [{ name: "description" }],
    };
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "Starter page",
      items: [
        {
          text: "Utility",
          href: "/",
        },
        {
          text: "Starter page",
          active: true,
        },
      ],

      ismobile: false,
      activeNames: [],
      activeapp: [],
      showmodel: "1",
      userinfo: [],
      safeinfo: [],
      loading: true,
      block: false,
      form: {},
    };
  },
  mounted() {
    this.title = this.$t("menuitems.setting.commonly");
    this.items = [
      {
        text: this.$t("menuitems.setting.text"),
        href: "/",
      },
      {
        text: this.$t("menuitems.setting.commonly"),
        active: true,
      },
    ];

    this.userinfo = JSON.parse(localStorage.getItem("user"));
    if (this.userinfo.topuid >= 1) {
      this.block = true;
    }
    let flag = navigator.userAgent.match(
      /(phone|pod|iPhone|iPod|ios|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
    );
    if (flag) {
      this.ismobile = true;
    }
    if(!this.block){
      this.getshopinfo();
    }
  },
  methods: {
    getshopinfo() {
      var that = this;
      that.$axios
        .post(
          that.apiuri,
          {
            action: "shopinfo",
          },
          {
            headers: { Openid: that.userinfo.openid },
          }
        )
        .then(function (response) {
          that.loading = false;
          if (response.data.data) {
            that.form = response.data.data;
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    saveshopinfo() {
      var that = this;
      that.loading = true;
      that.$axios
        .post(
          that.apiuri,
          {
            action: "saveshopinfo",
            name: that.form.name,
            tel: that.form.tel,
            address: that.form.address,
          },
          {
            headers: { Openid: that.userinfo.openid },
          }
        )
        .then(function (response) {
          that.loading = false;
          if (response.data.status === 200) {
            that.$message({
              message: that.$t("global.success"),
              type: "success",
            });
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body" v-loading="loading" v-if="!block">
            <el-form ref="form" :model="form" label-width="80px">
              <el-form-item :label="$t('setting.commonly.shopname')">
                <el-input v-model="form.name"></el-input>
              </el-form-item>
              <el-form-item :label="$t('setting.commonly.tel')">
                <el-input v-model="form.tel"></el-input>
              </el-form-item>
              <el-form-item :label="$t('setting.commonly.address')">
                <el-input type="textarea" v-model="form.address"></el-input>
              </el-form-item>
              <el-form-item class="text-right">
                <el-button type="primary" @click="saveshopinfo">
                  {{ $t("etting.commonly.save") }}
                </el-button>
              </el-form-item>
            </el-form>
          </div>
          <div v-else>
            <el-result
              icon="error"
              :title="$t('global.error')"
              :subTitle="$t('global.text.nopermission')"
            >
              <template slot="extra">
                <el-button type="primary" size="medium">返回</el-button>
              </template>
            </el-result>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>