<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";

/**
 * Starter page component
 */
export default {
  page() {
    return {
      title: this.title,
      meta: [{ name: "description" }],
    };
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "Starter page",
      items: [
        {
          text: "Utility",
          href: "/",
        },
        {
          text: "Starter page",
          active: true,
        },
      ],

      ismobile: false,
      activeNames: [],
      activeapp: [],
      showmodel: "1",
      userinfo: [],
      goodsinfo: {
        title: "",
        description: "",
      },
      loading: true,
      goodsid: 0,
      showsuccess: false,
    };
  },
  mounted() {
    this.title = this.$t("menuitems.goods.add");
    this.items = [
      {
        text: this.$t("menuitems.goods.text"),
        href: "/",
      },
      {
        text: this.$t("menuitems.goods.add"),
        active: true,
      },
    ];

    this.userinfo = JSON.parse(localStorage.getItem("user"));
    let flag = navigator.userAgent.match(
      /(phone|pod|iPhone|iPod|ios|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
    );
    if (flag) {
      this.ismobile = true;
    }
    if (this.$route.query.id) {
      this.goodsid = this.$route.query.id;
      this.getgoodsdetail();
    } else {
      this.loading = false;
    }
  },
  methods: {
    getgoodsdetail() {
      var that = this;
      that.$axios
        .post(
          that.apiuri,
          {
            action: "getgoods",
            id: that.goodsid,
          },
          {
            headers: { Openid: that.userinfo.openid },
          }
        )
        .then(function (response) {
          that.loading = false;
          that.goodsinfo = response.data.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    savegoods() {
      var that = this;
      let postdata = {
        action: "addgoods",
        id: that.goodsid,
        title: that.goodsinfo.title,
        description: that.goodsinfo.description,
      };
      that.$axios
        .post(that.apiuri, postdata, {
          headers: { Openid: that.userinfo.openid },
        })
        .then(function (response) {
          that.$toast.clear();
          if (response.data.status == 200) {
            that.showsuccess = true;
          } else {
            that.$message.error(that.$t("global.delete.500"));
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body" v-loading="loading" v-if="!showsuccess">
            <form class="form-group" id="goodsaddform">
              <div class="form-group">
                <label for="goodstitle">{{ $t("goods.additem.title") }}</label>
                <input
                  type="text"
                  class="form-control"
                  id="goodstitle"
                  name="title"
                  v-model="goodsinfo.title"
                  :placeholder="$t('goods.additem.title')"
                />
              </div>
              <div class="form-group">
                <label for="goodsdescription">{{
                  $t("goods.additem.description")
                }}</label>
                <textarea
                  class="form-control"
                  id="goodsdescription"
                  name="goodsdescription"
                  v-model="goodsinfo.description"
                  rows="4"
                ></textarea>
              </div>
              <el-row :gutter="20">
                <el-col :span="13"
                  ><div class="grid-content bg-purple text-right">
                    <a
                      href="javascript:;"
                      class="btn btn-success btn-fw"
                      @click="savegoods"
                      >{{ $t("goods.additem.save") }}</a
                    >
                  </div></el-col
                >
                <el-col :span="11"
                  ><div class="grid-content bg-purple text-right">
                    <router-link
                      :to="{
                        path: '/goods/list',
                      }"
                    >
                      <a href="javascript:;" class="btn btn-light btn-fw">{{
                        $t("global.return")
                      }}</a>
                    </router-link>
                  </div></el-col
                >
              </el-row>
            </form>
          </div>
          <div v-else>
            <el-result icon="success" :title="$t('global.success')">
              <template slot="extra">
                <router-link
                  :to="{
                    path: '/goods/list',
                  }"
                >
                  <el-button type="primary" size="medium">{{
                    $t("global.return")
                  }}</el-button>
                </router-link>
              </template>
            </el-result>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>