<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";

/**
 * Dashboard component
 */
export default {
  components: { Layout, PageHeader },
  data() {
    return {
      title: "Dashboard",
      items: [
        {
          text: "Nazox",
        },
        {
          text: "Dashboard",
          active: true,
        },
      ],
      cpuused: 50,
      cpu_progresscolor: "#20a53a",
      memory: 0,
      memory_progresscolor: "#20a53a",
      hostname: "",
      statistics: [],
      options: {
        chart: {
          id: "dashchart",
        },
        xaxis: {
          categories: [],
        },
      },
      series: [],
      userinfo: [],
      iplist: {
        ipv4: [],
        ipv6: "",
      },
      noticelist: [],
    };
  },
  mounted() {
    var that = this;
    that.title = that.$t("dashboard.text");
    that.items = [
      {
        text: that.$t("dashboard.text"),
        href: "/",
      },
    ];
    that.userinfo = JSON.parse(localStorage.getItem("user"));
    if (localStorage.getItem("notice")) {
      that.noticelist = JSON.parse(localStorage.getItem("notice"));
    }

    that.websocket.ws.onmessage = function (res) {
      let wsdata = JSON.parse(res.data);
      if (wsdata.action === "cpustatus") {
        that.cpuused = Math.floor(wsdata.used * 100) / 100;
        that.getcpuused();
      }
      if (wsdata.action === "memory") {
        that.memory = Math.floor(wsdata.memusage);
        that.getmemory();
      }
      if (wsdata.action === "sysstatus") {
        that.hostname = wsdata.hostname;
      }
      if (wsdata.action === "7daydata") {
        that.statistics = wsdata.data;

        var date = [];
        var sale = [];
        var total = [];
        var number = [];
        for (var i = 0; i < wsdata.data.length; i++) {
          date.push(wsdata.data[i].date);
          sale.push(wsdata.data[i].sale);
          total.push(wsdata.data[i].sum);
          number.push(wsdata.data[i].total);
        }
        that.series = [
          {
            name: that.$t("dashboard.counts"),
            data: total,
          },
          {
            name: that.$t("dashboard.price"),
            data: sale,
          },
          {
            name: that.$t("dashboard.totalbeer"),
            data: number,
          },
        ];
        that.options.xaxis.categories = date;
      }
      if (wsdata.action === "readcard") {
        if (that.cardhex) {
        } else {
          that.$axios
            .post(
              that.apiuri,
              {
                action: "finduser",
                hex: wsdata.uid,
              },
              {
                headers: { Openid: that.userinfo.openid },
              }
            )
            .then(function (response) {
              let res = response.data;
              if (res.status == 200) {
                that.$message({
                  message: that.$t("global.ws.finduser"),
                  type: "success",
                });
                that.$router.push({
                  path: "/user/detail",
                  query: { id: res.data.id },
                });
              } else {
                that.cardhex = wsdata.uid;
                if (!that.bindcardhex && that.bindcarduid) {
                  that
                    .$confirm(
                      that.$t("global.ws.userbindcard"),
                      that.$t("global.delete.sureyouaction"),
                      {
                        confirmButtonText: that.$t("global.button.ok"),
                        cancelButtonText: that.$t("global.button.cancel"),
                        type: "warning",
                      }
                    )
                    .then(() => {
                      that.$axios
                        .post(
                          that.apiuri,
                          {
                            action: "bindnewcard",
                            hex: that.cardhex,
                            uid: that.bindcarduid,
                          },
                          {
                            headers: { Openid: that.userinfo.openid },
                          }
                        )
                        .then(function (response) {
                          that.loading = false;
                          if (response.data.status == 200) {
                            that.$message({
                              message: that.$t("global.ws.bindsuccess"),
                              type: "success",
                            });
                            that.$router.push({
                              path: "/user/list",
                            });
                          } else if (response.data.status == 403) {
                            that.$message({
                              message: that.$t("global.delete.auth"),
                              type: "warning",
                            });
                          } else {
                            that.$message.error(that.$t("global.delete.500"));
                          }
                          that.getadminlist();
                        })
                        .catch(function (error) {
                          console.log(error);
                        });
                    })
                    .catch(() => {});
                } else {
                  that.$router.push({
                    path: "/user/add",
                    query: { id: wsdata.uid },
                  });
                }
              }
            })
            .catch(function (error) {
              console.log(error);
            });
        }
      }
      if (wsdata.action === "hostaddress") {
        that.iplist.ipv4 = wsdata.internalIPv4;
        that.iplist.ipv6 = wsdata.internalIPv6;
      }
      if (wsdata.action === "notice") {
        wsdata["time"] = Date.parse(new Date());
        wsdata["read"] = 0;
        that.noticelist.unshift(wsdata);
        localStorage.setItem("notice", JSON.stringify(that.noticelist));

        if (wsdata.type2 === "device") {
          that.$notify({
            title: that.$t("global.notice." + wsdata.status),
            message:
              that.$t("global.notice.device", { device: wsdata.edid }) +
              " " +
              that.$t("global.notice." + wsdata.type1),
            type: wsdata.status,
          });
        } else if (wsdata.type2 === "service") {
          that.$notify({
            title: that.$t("global.notice." + wsdata.status),
            message:
              that.$t("global.notice.service", {
                service: that.$t("global.notice.server." + wsdata.service),
              }) +
              " " +
              that.$t("global.notice." + wsdata.type1),
            type: wsdata.status,
          });
        }
      } else if (wsdata.type2 === "stock") {
        that.$notify({
          title: that.$t("global.notice." + wsdata.status),
          message: that.$t("global.notice.stock", {
            edid: wsdata.edid,
            stock: wsdata.stock,
          }),
          type: wsdata.status,
        });
      }
    };
  },
  methods: {
    handdleMsg(msg) {},
    getcpuused() {
      if (this.cpuused >= 80) {
        this.cpu_progresscolor = "#f7b851";
      } else if (this.cpuused >= 90) {
        this.cpu_progresscolor = "#ff0017";
      } else {
        this.cpu_progresscolor = "#20a53a";
      }
    },
    getmemory() {
      if (this.memory >= 80) {
        this.memory_progresscolor = "#f7b851";
      } else if (this.memory >= 90) {
        this.memory_progresscolor = "#ff0017";
      } else {
        this.memory_progresscolor = "#20a53a";
      }
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-md-4 col-lg-4 col-sm-4">
        <div class="card card-block card-stretch card-height">
          <div class="card-body">
            <div class="text-center" dir="ltr">
              <vue-ellipse-progress
                :progress="cpuused"
                :angle="-90"
                :color="cpu_progresscolor"
                emptyColor="#999"
                fontSize="2rem"
              >
                <span slot="legend-value">%</span>
                <p slot="legend-caption">{{ $t("index.text.cpuload") }}</p>
              </vue-ellipse-progress>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4 col-lg-4 col-sm-4">
        <div class="card card-block card-stretch card-height">
          <div class="card-body">
            <div class="text-center" dir="ltr">
              <vue-ellipse-progress
                :progress="memory"
                :angle="-90"
                :color="memory_progresscolor"
                emptyColor="#999"
                fontSize="2rem"
              >
                <span slot="legend-value">%</span>
                <p slot="legend-caption">{{ $t("index.text.memoryused") }}</p>
              </vue-ellipse-progress>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4 col-lg-4 col-sm-4">
        <div class="card card-block card-stretch card-height">
          <div class="card-body">
            <div class="text-center" dir="ltr">
              <h4>
                <b>{{ hostname }}</b>
              </h4>
              <p i18n="hostname" class="mb-1">
                {{ $t("index.text.hostname") }}
              </p>
            </div>
          </div>
          <div class="card-body">
            <div class="text-center">
              <h5>
                <b>{{ iplist.ipv4[0] }}</b
                ><br />
                <b>{{ iplist.ipv6 }}</b>
              </h5>
              <p i18n="hostname" class="mb-1">
                {{ $t("index.text.ipaddress") }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <h5>{{ $t("dashboard.statistics") }}</h5>
            <el-empty
              :description="$t('dashboard.nostatistics')"
              v-if="statistics.length === 0"
            ></el-empty>
            <apexchart
              width="100%"
              v-else
              type="bar"
              :options="options"
              :series="series"
            ></apexchart>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>