<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";

/**
 * Starter page component
 */
export default {
  page() {
    return {
      title: this.$t("about.text"),
      meta: [{ name: "description" }],
    };
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "Starter page",
      items: [
        {
          text: "Utility",
          href: "/",
        },
        {
          text: "Starter page",
          active: true,
        },
      ],

      ismobile: false,
      activeNames: [],
      activeapp: [],
      loading: true,
    };
  },
  mounted() {
    this.title = this.$t("about.text");

    this.items = [
      {
        text: this.$t("menuitems.dashboard.text"),
        href: "/",
      },
      {
        text: this.$t("about.text"),
        active: true,
      },
    ];

    this.userinfo = JSON.parse(localStorage.getItem("user"));
    let flag = navigator.userAgent.match(
      /(phone|pod|iPhone|iPod|ios|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
    );
    if (flag) {
      this.ismobile = true;
    }
  },
  methods: {},
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <table class="table">
              <tbody>
                <tr>
                  <td>{{$t("about.group.system")}}</td>
                  <td>Powered By AICO-Wis Linux</td>
                </tr>
                <tr>
                  <td>{{$t("about.group.iotcore")}}</td>
                  <td>Powered By OpenHarmony</td>
                </tr>
                <tr>
                  <td>{{$t("about.group.core")}}</td>
                  <td>By Shijiazhuang MOE Information Technology Co., Ltd</td>
                </tr>
                <tr>
                  <td>{{$t("about.group.development")}}</td>
                  <td>By Shijiazhuang MOE Information Technology Co., Ltd</td>
                </tr>
                <tr>
                  <td>{{$t("system.version")}}</td>
                  <td>{{version}}</td>
                </tr>
                
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>