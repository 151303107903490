<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";

/**
 * Starter page component
 */
export default {
  page() {
    return {
      title: this.title,
      meta: [{ name: "description" }],
    };
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "Starter page",
      items: [
        {
          text: "Utility",
          href: "/",
        },
        {
          text: "Starter page",
          active: true,
        },
      ],

      ismobile: false,
      activeNames: [],
      activeapp: [],
      showmodel: "1",
      userinfo: [],
      safeinfo: [],
      loading: true,
      systeminfo: [],
      newuserinfo: {
        password: "",
        repassword: "",
      },
      showsuccess: false,
    };
  },
  mounted() {
    this.title = this.$t("menuitems.setting.account");
    this.items = [
      {
        text: this.$t("menuitems.setting.text"),
        href: "/",
      },
      {
        text: this.$t("menuitems.setting.account"),
        active: true,
      },
    ];

    this.userinfo = JSON.parse(localStorage.getItem("user"));
    let flag = navigator.userAgent.match(
      /(phone|pod|iPhone|iPod|ios|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
    );
    if (flag) {
      this.ismobile = true;
    }
    this.getsysteminfo();
  },
  methods: {
    getsysteminfo() {
      var that = this;
      that.$axios
        .post(
          that.apiuri,
          {
            action: "systeminfo",
          },
          {
            headers: { Openid: that.userinfo.openid },
          }
        )
        .then(function (response) {
          that.loading = false;
          that.systeminfo = response.data.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    onSubmit() {
      var that = this;
      that.$axios
        .post(
          that.apiuri,
          {
            action: "changeadminpwd",
            password: that.newuserinfo.password,
          },
          {
            headers: { Openid: that.userinfo.openid },
          }
        )
        .then(function (response) {
          if (response.data.status == 200) {
            that.showsuccess = true;
            setTimeout(function(){
              localStorage.removeItem("user")
              that.userinfo=[]
              window.location.href="/login"
            },5000)
          } else {
            that.$message.error(response.data.msg);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body" v-loading="loading" v-if="!showsuccess">
            <el-form ref="form" label-width="80px">
              <el-form-item :label="$t('setting.account.username')">
                {{ userinfo.username }}
              </el-form-item>
              <el-form-item :label="$t('admin.add.password')">
                <el-input
                  type="password"
                  v-model="newuserinfo.password"
                  show-password
                ></el-input>
                <el-alert
                  :title="$t('setting.account.passwordlegth')"
                  v-if="
                    newuserinfo.password.length > 0 &&
                    newuserinfo.password.length < 8
                  "
                  type="error"
                >
                </el-alert>
              </el-form-item>

              <el-form-item :label="$t('admin.add.reinputpassword')">
                <el-input
                  type="password"
                  v-model="newuserinfo.repassword"
                  show-password
                ></el-input>
                <el-alert
                  :title="$t('setting.account.passwordmatch')"
                  v-if="
                    newuserinfo.password.length > 0 &&
                    newuserinfo.repassword.length > 0 &&
                    newuserinfo.password != newuserinfo.repassword
                  "
                  type="error"
                >
                </el-alert>
              </el-form-item>
              <el-form-item
                v-if="
                  newuserinfo.password === newuserinfo.repassword &&
                  newuserinfo.password &&
                  newuserinfo.password.length >= 8
                "
              >
                <el-button type="primary" @click="onSubmit">{{
                  $t("setting.account.save")
                }}</el-button>
              </el-form-item>
            </el-form>
          </div>
          <div class="card-body" v-else>
            <el-result
              icon="success"
              :title="$t('global.change.success')"
              :subTitle="$t('setting.account.relogintip')"
            >
              <template slot="extra">
                <el-button type="primary" size="medium">
                  <a href="/logout" style="color:#fff">
                  {{$t("global.button.ok")}}
                  </a>
                </el-button>
              </template>
            </el-result>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>