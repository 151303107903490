<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";

/**
 * Starter page component
 */
export default {
  page() {
    return {
      title: this.title,
      meta: [{ name: "description" }],
    };
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "Starter page",
      items: [
        {
          text: "Utility",
          href: "/",
        },
        {
          text: "Starter page",
          active: true,
        },
      ],

      ismobile: false,
      activeNames: [],
      activeapp: [],
      showmodel: "1",
      userinfo: [],
      admininfo: {
        username: "",
        phone: "",
        country: 86,
      },
      userpassword: "",
      reinputuserpassword: "",
      loading: true,
      adminid: 0,
      showsuccess: false,
      countrylist: [],
      systemtype: 0,
      card: "",
      type: true
    };
  },
  mounted() {
    var that = this;
    that.title = that.$t("menuitems.user.add");
    that.items = [
      {
        text: that.$t("menuitems.user.text"),
        href: "/",
      },
      {
        text: that.$t("menuitems.user.add"),
        active: true,
      },
    ];

    that.userinfo = JSON.parse(localStorage.getItem("user"));
    let flag = navigator.userAgent.match(
      /(phone|pod|iPhone|iPod|ios|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
    );
    if (flag) {
      that.ismobile = true;
    }
    if (that.$route.query.id) {
      that.adminid = that.$route.query.id;
      that.getgadmindetail();
    } else {
      that.loading = false;
    }
    that.countrylist = [
      {
        value: 86,
        label: that.$t("global.country.china"),
      },
      {
        value: 1,
        label: that.$t("global.country.usa"),
      },
      {
        value: 81,
        label: that.$t("global.country.japan"),
      },
      {
        value: 44,
        label: that.$t("global.country.uk"),
      },
      {
        value: 34,
        label: that.$t("global.country.spain"),
      },
      {
        value: 33,
        label: that.$t("global.country.french"),
      },
      {
        value: 60,
        label: that.$t("global.country.malaysia"),
      },
      {
        value: 65,
        label: that.$t("global.country.singapore"),
      },
    ];
    that.getsysteminfo();
    that.card = that.$route.query.id;
    var numReg = /^[0-9]*$/
    var numRe = new RegExp(numReg)
    if (!numRe.test(that.card)){
      that.type=false
    }
  },
  methods: {
    getsysteminfo() {
      var that = this;
      that.$axios
        .post(
          that.apiuri,
          {
            action: "systeminfo",
          },
          {
            headers: { Openid: that.userinfo.openid },
          }
        )
        .then(function (response) {
          that.systemtype = parseInt(response.data.data.model);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    getgadmindetail() {
      var that = this;
      that.$axios
        .post(
          that.apiuri,
          {
            action: "getuserinfo",
            id: that.adminid,
          },
          {
            headers: { Openid: that.userinfo.openid },
          }
        )
        .then(function (response) {
          that.loading = false;
          let newdata = response.data.data;
          that.admininfo = {
            username: newdata.username,
            phone: newdata.phone,
            country: newdata.country,
          };
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    addadminuser() {
      var that = this;
      if (!that.admininfo.username) {
        that.$message.error(that.$t("admin.add.usernotintut"));
        return;
      }
      if (that.admininfo.username < 4) {
        that.$message.error(that.$t("admin.add.userlong"));
        return;
      }
      if (!that.admininfo.phone) {
        that.$message.error(that.$t("admin.add.phonenotintut"));
        return;
      }
      if (that.admininfo.phone < 11) {
        that.$message.error(that.$t("admin.add.phonelong"));
        return;
      }
      let postdata = {
          action: "addnewuser",
          id: that.adminid,
          phone: that.admininfo.phone,
          country: that.admininfo.country,
          username: that.admininfo.username,
          password: that.reinputuserpassword,
          hex: that.card,
        };
      console.log(that.card)
      that.$axios
        .post(that.apiuri, postdata, {
          headers: { Openid: that.userinfo.openid },
        })
        .then(function (response) {
          that.$toast.clear();
          if (response.data.status == 200) {
            that.showsuccess = true;
          } else {
            if (response.data.msg) {
              that.$message.error(
                that.$t("user.add.error." + response.data.msg)
              );
            } else {
              that.$message.error(that.$t("global.delete.500"));
            }
          }
        })
        .catch(function (error) {
          $t("user.add.error.havenopayorder")
          console.log(error);
        });
    },
    addwhitelist() {
      var that = this;
      if (!that.admininfo.username) {
        that.$message.error(that.$t("admin.add.usernotintut"));
        return;
      }
      that.$axios
        .post(
          that.apiuri,
          {
            action: "addwhitelist",
            id: that.card,
            user: that.admininfo.username
          },
          {
            headers: { Openid: that.userinfo.openid },
          }
        )
        .then(function (response) {
          that.$toast.clear();
          if (response.data.status == 200) {
            that.showsuccess = true;
          } else {
            if (response.data.msg) {
              that.$message.error(
                that.$t("admin.add.error." + response.data.msg)
              );
            } else {
              that.$message.error(that.$t("global.delete.500"));
            }
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    checkinputphone(val) {
      console.log(val);
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body" v-loading="loading" v-if="!showsuccess">
            <form class="form-group" id="adminaddform">
              <div class="form-group">
                <label for="username">{{ $t("admin.add.username") }}</label>
                <input
                  type="text"
                  class="form-control"
                  id="username"
                  name="username"
                  v-model="admininfo.username"
                  :placeholder="$t('admin.add.username')"
                />
              </div>
              <div class="form-group">
                <label for="phone">{{ $t("admin.add.phone") }}</label>
                <el-row>
                  <el-col :span="6"
                    ><div class="grid-content bg-purple">
                      <el-select
                        v-model="admininfo.country"
                        :placeholder="$t('admin.add.selectcountry')"
                        filterable
                      >
                        <el-option
                          v-for="item in countrylist"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"
                        >
                          <span style="float: left">{{ item.label }}</span>
                          <span
                            style="
                              float: right;
                              color: #8492a6;
                              font-size: 13px;
                            "
                            >{{ item.value }}</span
                          >
                        </el-option>
                      </el-select>
                    </div></el-col
                  >
                  <el-col :span="18"
                    ><div class="grid-content bg-purple-light">
                      <el-input
                        :placeholder="$t('admin.add.phone')"
                        v-model="admininfo.phone"
                        maxlength="11"
                      >
                      </el-input></div
                  ></el-col>
                </el-row>
              </div>
              <el-row :gutter="20">
                <el-col :span="6"
                  ><div class="grid-content bg-purple">
                    <a
                      v-if="card && systemtype === 2 && !type"
                      href="javascript:;"
                      class="btn btn-info btn-fw"
                      @click="addwhitelist"
                      >{{ $t("user.add.temp") }}</a
                    >
                  </div></el-col
                >
                <el-col :span="12"
                  ><div class="grid-content bg-purple text-center">
                    <a
                      href="javascript:;"
                      class="btn btn-success btn-fw"
                      @click="addadminuser"
                      >{{ $t("goods.additem.save") }}</a
                    >
                  </div></el-col
                >
                <el-col :span="6"
                  ><div class="grid-content bg-purple text-right">
                    <router-link
                      :to="{
                        path: '/user/list',
                      }"
                    >
                      <a href="javascript:;" class="btn btn-light btn-fw">{{
                        $t("global.return")
                      }}</a>
                    </router-link>
                  </div></el-col
                >
              </el-row>
            </form>
          </div>
          <div v-else>
            <el-result icon="success" :title="$t('global.success')">
              <template slot="extra">
                <router-link
                  :to="{
                    path: '/user/list',
                  }"
                >
                  <el-button type="primary" size="medium">{{
                    $t("global.return")
                  }}</el-button>
                </router-link>
              </template>
            </el-result>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>