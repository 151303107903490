<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";

/**
 * Starter page component
 */
export default {
  page() {
    return {
      title: this.$t("menuitems.devices.console"),
      meta: [{ name: "description" }],
    };
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "Starter page",
      items: [
        {
          text: "Utility",
          href: "/",
        },
        {
          text: "Starter page",
          active: true,
        },
      ],

      ismobile: false,
      activeNames: [],
      activeapp: [],
      loading: true,
      deviceid: "",
      service: {
        status: 404,
      },
      filelist: [],
      package: [],
      buyconfig: {
        backupnumber: 5,
        backuptype: "1",
        baseprice: "",
        price: 600,
        time: 1,
      },
      paywayselect: false,
      paywaycoade: "alipay",
      payloading: true,
      paymentqrcode: "",
      paystatustimer: "",
      paysn: "",
    };
  },
  mounted() {
    var that = this;
    that.title = that.$t("menuitems.app.backup");

    that.items = [
      {
        text: that.$t("menuitems.app.text"),
        href: "/",
      },
      {
        text: that.$t("menuitems.app.backup"),
        active: true,
      },
    ];

    that.userinfo = JSON.parse(localStorage.getItem("user"));
    let flag = navigator.userAgent.match(
      /(phone|pod|iPhone|iPod|ios|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
    );
    if (flag) {
      that.ismobile = true;
    }
    that.websocket.ws.onmessage = function (res) {
      let wsdata = JSON.parse(res.data);
      if (wsdata.action === "deviceid") {
        that.deviceid = wsdata.id.replace(/\n|\r/g, "");
        if (that.deviceid) {
          that.checkappstatus();
        }
      }
    };
    that.getdeviceid();
  },
  methods: {
    checkappstatus() {
      var that = this;
      that.$axios
        .post(
          that.deviceapi,
          {
            action: "backupstatus",
            device: that.$utils.encryptv2(that.deviceid, that.aeskey),
          },
          {
            headers: { Openid: that.userinfo.openid },
          }
        )
        .then(function (response) {
          let data = response.data;

          if (data.status == 200) {
            // that.service = JSON.parse(data.data;
            if (that.paywayselect == ture) {
              that.paywayselect = false;
              // clearInterval(that.paystatustimer);
            }
            let service = that.$utils.decrypt(
              data.data.data,
              that.aeskey,
              data.data.iv
            );
            service = service.slice(0, -15);
            that.service = JSON.parse(service);
          }
          that.service.status = data.status;
          that.loading = false;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    getdeviceid() {
      var that = this;
      let senddata = {
        action: "deviceid",
      };
      that.websocket.ws.send(JSON.stringify(senddata));
    },
    getbackuplist() {
      var that = this;
      that.$axios
        .post(
          that.deviceapi,
          {
            action: "backuplist",
            device: that.deviceid,
          },
          {
            headers: { Openid: that.userinfo.openid },
          }
        )
        .then(function (response) {
          let data = response.data;
          that.filelist = data.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    buybackup() {
      var that = this;
      that.paywayselect = true;
      that.paymentqrcode = "";
      if (that.paywaycoade) {
        that.payloading = true;
        that.$axios
          .post(
            that.deviceapi,
            {
              action: "buybackup",
              datas: that.$utils.encryptv2(
                JSON.stringify(that.buyconfig),
                that.aeskey
              ),
              device: that.$utils.encryptv2(that.deviceid, that.aeskey),
              payway: that.paywaycoade,
            },
            {
              headers: { Openid: that.userinfo.openid },
            }
          )
          .then(function (response) {
            let data = response.data;
            that.payloading = false;
            that.paymentqrcode = data.qrcode;
            // that.paystatustimer = setInterval(() => {
            //   that.checkappstatus();
            // }, 5000);
          })
          .catch(function (error) {
            that.payloading = false;
          });
      }
    },
    changepayway(tab, event) {
      // this.paywaycoade = payway
      this.buybackup();
      // console.log(this.paywaycoade)
    },
    calculator() {
      let price = 0;
      price = price + this.buyconfig.backupnumber * 50;
      if (this.buyconfig.backuptype == 1) {
        price = price + 350;
      } else {
        price = price + 950;
      }
      price = price * this.buyconfig.time;
      this.buyconfig.baseprice = price;
      if (this.buyconfig.time >= 12) price = price * 0.8;
      this.buyconfig.price = price;
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body" v-loading="loading">
            <div v-if="service.status == 404">
              <el-dialog
                :title="$t('app.backup.paytype')"
                :visible.sync="paywayselect"
              >
                <el-tabs
                  v-model="paywaycoade"
                  type="border-card"
                  @tab-click="changepayway"
                >
                  <el-tab-pane
                    :label="$t('app.backup.payway.alipay')"
                    name="alipay"
                    style="text-align: center"
                  >
                    <el-image
                      :src="paymentqrcode"
                      v-loading="payloading"
                      style="width: 400px; height: 400px"
                    >
                      <div slot="placeholder" class="image-slot">
                        加载中<span class="dot">...</span>
                      </div>
                    </el-image>
                  </el-tab-pane>
                  <el-tab-pane
                    :label="$t('app.backup.payway.wxpay')"
                    name="wxpay"
                    style="text-align: center"
                  >
                    <el-image
                      :src="paymentqrcode"
                      v-loading="payloading"
                      style="width: 400px; height: 400px"
                    >
                      <div slot="placeholder" class="image-slot">
                        加载中<span class="dot">...</span>
                      </div>
                    </el-image>
                  </el-tab-pane>
                </el-tabs>
              </el-dialog>
              <div class="card-title">{{ $t("app.backup.title") }}</div>
              <div class="backuppackage">
                <el-form ref="form" :model="buyconfig" label-width="100px">
                  <div class="row">
                    <div class="col-8">
                      <el-form-item :label="$t('app.backup.backupnumber')">
                        <van-stepper
                          v-model="buyconfig.backupnumber"
                          min="5"
                          max="100"
                          disable-input
                          async-change
                          @change="calculator"
                        />
                      </el-form-item>
                      <el-form-item :label="$t('app.backup.backuptype')">
                        <el-select
                          v-model="buyconfig.backuptype"
                          @change="calculator"
                        >
                          <el-option
                            value="1"
                            :label="$t('app.backup.type.data')"
                          ></el-option>
                          <el-option
                            value="2"
                            :label="$t('app.backup.type.device')"
                          ></el-option>
                        </el-select>
                      </el-form-item>
                      <el-form-item :label="$t('app.backup.backupservicetime')">
                        <van-stepper
                          v-model="buyconfig.time"
                          min="1"
                          max="36"
                          async-change
                          @change="calculator"
                        />
                      </el-form-item>
                    </div>
                    <div class="col-4">
                      <p>
                        {{ $t("app.backup.backupnumber") }}:{{
                          buyconfig.backupnumber
                        }}
                      </p>
                      <p>
                        {{ $t("app.backup.backuptype") }}:
                        <span v-if="buyconfig.backuptype == 1">{{
                          $t("app.backup.type.data")
                        }}</span>
                        <span v-else-if="buyconfig.backuptype == 2">{{
                          $t("app.backup.type.device")
                        }}</span>
                      </p>
                    </div>
                  </div>
                </el-form>
                <van-submit-bar
                  :loading="loading"
                  :price="buyconfig.price"
                  :button-text="$t('app.backup.buy')"
                  :suffix-label="buyconfig.time + $t('app.backup.month')"
                  @submit="buybackup"
                />
              </div>
            </div>
            <div v-else>Info</div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>