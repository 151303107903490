var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Layout',[_c('PageHeader',{attrs:{"title":_vm.title,"items":_vm.items}}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"card"},[(!_vm.showsuccess)?_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"card-body"},[_c('el-form',{ref:"form",attrs:{"label-width":"80px"}},[_c('el-form-item',{attrs:{"label":_vm.$t('setting.account.username')}},[_vm._v(" "+_vm._s(_vm.userinfo.username)+" ")]),_c('el-form-item',{attrs:{"label":_vm.$t('admin.add.password')}},[_c('el-input',{attrs:{"type":"password","show-password":""},model:{value:(_vm.newuserinfo.password),callback:function ($$v) {_vm.$set(_vm.newuserinfo, "password", $$v)},expression:"newuserinfo.password"}}),(
                  _vm.newuserinfo.password.length > 0 &&
                  _vm.newuserinfo.password.length < 8
                )?_c('el-alert',{attrs:{"title":_vm.$t('setting.account.passwordlegth'),"type":"error"}}):_vm._e()],1),_c('el-form-item',{attrs:{"label":_vm.$t('admin.add.reinputpassword')}},[_c('el-input',{attrs:{"type":"password","show-password":""},model:{value:(_vm.newuserinfo.repassword),callback:function ($$v) {_vm.$set(_vm.newuserinfo, "repassword", $$v)},expression:"newuserinfo.repassword"}}),(
                  _vm.newuserinfo.password.length > 0 &&
                  _vm.newuserinfo.repassword.length > 0 &&
                  _vm.newuserinfo.password != _vm.newuserinfo.repassword
                )?_c('el-alert',{attrs:{"title":_vm.$t('setting.account.passwordmatch'),"type":"error"}}):_vm._e()],1),(
                _vm.newuserinfo.password === _vm.newuserinfo.repassword &&
                _vm.newuserinfo.password &&
                _vm.newuserinfo.password.length >= 8
              )?_c('el-form-item',[_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.onSubmit}},[_vm._v(_vm._s(_vm.$t("setting.account.save")))])],1):_vm._e()],1)],1):_c('div',{staticClass:"card-body"},[_c('el-result',{attrs:{"icon":"success","title":_vm.$t('global.change.success'),"subTitle":_vm.$t('setting.account.relogintip')}},[_c('template',{slot:"extra"},[_c('el-button',{attrs:{"type":"primary","size":"medium"}},[_c('a',{staticStyle:{"color":"#fff"},attrs:{"href":"/logout"}},[_vm._v(" "+_vm._s(_vm.$t("global.button.ok"))+" ")])])],1)],2)],1)])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }