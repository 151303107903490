<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
/**
 * Starter page component
 */
export default {
  page() {
    return {
      title: this.title,
      meta: [{ name: "description" }],
    };
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "Starter page",
      items: [
        {
          text: "Utility",
          href: "/",
        },
        {
          text: "Starter page",
          active: true,
        },
      ],

      ismobile: false,
      activeNames: [],
      activeapp: [],
      showmodel: "1",
      userinfo: [],
      safeinfo: [],
      loading: true,
      block: false,
      networkcardlist: [],
      wifilist: [],
      lanport: "",
      laniptype: 0,
      wlanport: "",
      dnsinfo: [],
      wlandata: {
        ssid: "",
        password: "",
      },
      wlanloading: true,
      dnsloading: false,
      systemtypeloading: true,
      systemtypes: [
        {
          value: "0",
          label: this.$t("setting.system.default"),
        },
        {
          value: "1",
          label: this.$t("setting.system.counter"),
        },
        {
          value: "2",
          label: this.$t("setting.system.card"),
        },
      ],
      systemtype: 0,
      systeminfo: [],
      tcpport: "",
      tcploading: true,
      ipv4reg:
        "/^((25[0-5]|2[0-4]d|((1d{2})|([1-9]?d))).){3}(25[0-5]|2[0-4]d|((1d{2})|([1-9]?d))|*)((/([012]d|3[012]|d))?)(,((25[0-5]|2[0-4]d|((1d{2})|([1-9]?d))).){3}(25[0-5]|2[0-4]d|((1d{2})|([1-9]?d))|*)((/([012]d|3[012]|d))?))*$/",
      ipv6reg:
        "/^s*((([0-9A-Fa-f]{1,4}:){7}([0-9A-Fa-f]{1,4}|:))|(([0-9A-Fa-f]{1,4}:){6}(:[0-9A-Fa-f]{1,4}|((25[0-5]|2[0-4]d|1dd|[1-9]?d)(.(25[0-5]|2[0-4]d|1dd|[1-9]?d)){3})|:))|(([0-9A-Fa-f]{1,4}:){5}(((:[0-9A-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]d|1dd|[1-9]?d)(.(25[0-5]|2[0-4]d|1dd|[1-9]?d)){3})|:))|(([0-9A-Fa-f]{1,4}:){4}(((:[0-9A-Fa-f]{1,4}){1,3})|((:[0-9A-Fa-f]{1,4})?:((25[0-5]|2[0-4]d|1dd|[1-9]?d)(.(25[0-5]|2[0-4]d|1dd|[1-9]?d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){3}(((:[0-9A-Fa-f]{1,4}){1,4})|((:[0-9A-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]d|1dd|[1-9]?d)(.(25[0-5]|2[0-4]d|1dd|[1-9]?d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){2}(((:[0-9A-Fa-f]{1,4}){1,5})|((:[0-9A-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]d|1dd|[1-9]?d)(.(25[0-5]|2[0-4]d|1dd|[1-9]?d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){1}(((:[0-9A-Fa-f]{1,4}){1,6})|((:[0-9A-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]d|1dd|[1-9]?d)(.(25[0-5]|2[0-4]d|1dd|[1-9]?d)){3}))|:))|(:(((:[0-9A-Fa-f]{1,4}){1,7})|((:[0-9A-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]d|1dd|[1-9]?d)(.(25[0-5]|2[0-4]d|1dd|[1-9]?d)){3}))|:)))(%.+)?s*$/",
    };
  },
  mounted() {
    var that = this;
    that.title = that.$t("menuitems.setting.system");
    that.items = [
      {
        text: that.$t("menuitems.setting.text"),
        href: "/",
      },
      {
        text: that.$t("menuitems.setting.system"),
        active: true,
      },
    ];

    that.userinfo = JSON.parse(localStorage.getItem("user"));
    if (that.userinfo.topuid >= 1) {
      that.block = true;
    }
    let flag = navigator.userAgent.match(
      /(phone|pod|iPhone|iPod|ios|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
    );
    if (flag) {
      that.ismobile = true;
    }
    that.getnetworkcardlist();
    that.getdnsinfo();
    that.getwifilist();
    that.getsysteminfo();
    that.gettcpinfo();
    that.websocket.ws.onmessage = function (res) {
      let wsdata = JSON.parse(res.data);

      if (wsdata.action === "netcard") {
        that.networkcardlist = wsdata.networkInterfaces;
      }
      if (wsdata.action === "dnsinfo") {
        that.dnsinfo = wsdata.dns;
      }
      if (wsdata.action === "scanwifi") {
        that.wlanloading = false;
        that.wifilist = wsdata.ssid;
        for (let i = 0; i < that.wifilist.length; i++) {
          if (that.wifilist[i].use === "*") {
            that.wlandata.ssid = that.wifilist[i].ssid;
          }
        }
      }
      if (wsdata.action === "connectwifi") {
        if (wsdata.msg) {
          that.getnetworkcardlist();
          that.$message({
            message: that.$t("setting.system.network.wlansuccess", {
              ssid: that.wlandata.ssid,
            }),
            type: "success",
          });
          that.wlanloading = false;
        }
      }
      if (wsdata.action === "disconnectwifi") {
        if (wsdata.msg) {
          that.getnetworkcardlist();
          that.$message({
            message: that.$t("setting.system.network.wlandisconnect", {
              ssid: that.wlandata.ssid,
            }),
            type: "success",
          });
          that.wlandata.ssid = "";
          that.wlandata.password = "";
          that.wlanloading = false;
        }
      }
      if (wsdata.action === "savedns") {
        that.$message({
          message: that.$t("setting.system.network.dnssuccess"),
          type: "success",
        });
      }
      if (wsdata.action === "changeipaddress") {
        if (wsdata.msg.code === 0) {
          that.$message({
            message: that.$t("setting.system.network.ipsuccess"),
            type: "success",
          });
        }
      }
    };
  },
  methods: {
    getsysteminfo() {
      var that = this;
      that.$axios
        .post(
          that.apiuri,
          {
            action: "systeminfo",
          },
          {
            headers: { Openid: that.userinfo.openid },
          }
        )
        .then(function (response) {
          that.systemtypeloading = false;
          that.systeminfo = response.data.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    getnetworkcardlist() {
      var that = this;
      let senddata = {
        action: "getnetcard",
      };
      that.websocket.ws.send(JSON.stringify(senddata));
    },
    getwifilist() {
      var that = this;
      let senddata = {
        action: "scanwifi",
      };
      that.websocket.ws.send(JSON.stringify(senddata));
    },
    getdnsinfo() {
      var that = this;
      let senddata = {
        action: "dnsinfo",
      };
      that.websocket.ws.send(JSON.stringify(senddata));
    },
    networkcardfilter() {
      var that = this;
      let array = [];
      array = this.networkcardlist;
      console.log(array);
      let newMapArr = array.every((item, i) => {
        console.log(i);
      });
    },
    ipcheck(type, value) {
      let check = false;
      if (type === "ipv4") {
        check = value;
      }
    },
    onSubmit() {
      var that = this;
      if (that.lanport.indexOf("eth") != -1) {
        let senddata = {
          action: "changeipaddress",
          data: that.networkcardlist[that.lanport][that.laniptype],
          card: that.lanport,
        };
        that.websocket.ws.send(JSON.stringify(senddata));
      }
      if (that.lanport.indexOf("wlan") != -1) {
        let senddata = {
          action: "changeipaddress",
          data: that.networkcardlist[that.lanport][that.laniptype],
          card: that.wlandata.ssid,
        };
        that.websocket.ws.send(JSON.stringify(senddata));
      }
    },
    dhcp() {
      var that = this;
      if (that.lanport.indexOf("eth") != -1) {
        let senddata = {
          action: "changeipdhcp",
          family: that.networkcardlist[that.lanport][that.laniptype].family,
          card: that.lanport,
        };
        that.websocket.ws.send(JSON.stringify(senddata));
      }
      if (that.lanport.indexOf("wlan") != -1) {
        let senddata = {
          action: "changeipdhcp",
          family: that.networkcardlist[that.lanport][that.laniptype].family,
          card: that.wlandata.ssid,
        };
        that.websocket.ws.send(JSON.stringify(senddata));
      }
    },
    savedns() {
      var that = this;
      let senddata = {
        action: "savedns",
        dns: that.dnsinfo,
      };
      that.websocket.ws.send(JSON.stringify(senddata));
    },
    savewlan() {
      var that = this;
      let senddata = {
        action: "connectwifi",
        ssid: that.wlandata.ssid,
        password: that.wlandata.password,
      };
      that.websocket.ws.send(JSON.stringify(senddata));
      that.wlanloading = true;
    },
    disconnectwlan() {
      var that = this;
      let senddata = {
        action: "disconnectwifi",
        ssid: that.wlandata.ssid,
      };
      that.websocket.ws.send(JSON.stringify(senddata));
      that.wlanloading = true;
    },
    changesystemmodel() {
      var that = this;
      that.systemtypeloading = true;
      that.$axios
        .post(
          that.apiuri,
          {
            action: "savesystemmodel",
            type: that.systeminfo.model,
          },
          {
            headers: { Openid: that.userinfo.openid },
          }
        )
        .then(function (response) {
          that.systemtypeloading = false;
          that.$message({
            message: that.$t("global.success"),
            type: "success",
          });
          that.getsysteminfo();
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    reboottcp() {
      var that = this;
      let senddata = {
        action: "retcpserver",
      };
      that.websocket.ws.send(JSON.stringify(senddata));
      that.wlanloading = true;
    },
    gettcpinfo() {
      var that = this;
      that.tcploading = true;
      that.$axios
        .post(
          that.apiuri,
          {
            action: "gettcpport",
          },
          {
            headers: { Openid: that.userinfo.openid },
          }
        )
        .then(function (response) {
          that.tcploading = false;
          that.tcpport = response.data.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    savetcpport() {
      var that = this;
      that.tcploading = true;
      that.$axios
        .post(
          that.apiuri,
          {
            action: "changetcpport",
            port: that.tcpport,
          },
          {
            headers: { Openid: that.userinfo.openid },
          }
        )
        .then(function (response) {
          that.tcploading = false;
          that.$message({
            message: that.$t("global.success"),
            type: "success",
          });
          that.gettcpinfo();
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  },
  computed: {
    lancardlist() {
      var datas = this.networkcardlist;
      datas = datas.filter((item, idx) => {
        console.log(idx);
      });

      return datas;
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row" v-if="!block">
      <div class="col-6">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">{{ $t("setting.system.network.lan") }}</h5>
            <el-form ref="form" label-width="80px">
              <el-form-item :label="$t('setting.system.network.lancard')">
                <el-select
                  v-model="lanport"
                  :placeholder="$t('global.text.select')"
                >
                  <el-option
                    v-for="(item, index) in networkcardlist"
                    :key="index"
                    :label="index"
                    :value="index"
                    v-show="
                      index.indexOf('eth') != -1 || index.indexOf('wlan') != -1
                    "
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item
                :label="$t('setting.system.network.iptype')"
                v-if="lanport"
              >
                <el-select
                  v-model="laniptype"
                  :placeholder="$t('global.text.select')"
                >
                  <el-option
                    v-for="(item, index) in networkcardlist[lanport]"
                    :key="index"
                    :label="item.family"
                    :value="index"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item
                :label="$t('setting.system.network.ipaddress')"
                v-if="laniptype >= 0 && lanport"
              >
                <el-input
                  v-model="networkcardlist[lanport][laniptype].address"
                  :placeholder="$t('global.text.input')"
                ></el-input>
              </el-form-item>
              <el-form-item
                :label="$t('setting.system.network.mac')"
                v-if="laniptype >= 0 && lanport"
              >
                <el-input
                  v-model="networkcardlist[lanport][laniptype].mac"
                  :disabled="true"
                  :placeholder="$t('global.text.input')"
                ></el-input>
              </el-form-item>
              <el-form-item>
                <el-button
                  type="primary"
                  v-if="laniptype >= 0 && lanport"
                  @click="onSubmit"
                  >{{ $t("setting.system.network.save") }}</el-button
                >
                <el-button v-if="laniptype >= 0 && lanport" @click="dhcp">{{
                  $t("setting.system.network.dhcp")
                }}</el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>
      </div>
      <div class="col-6">
        <div class="card">
          <div class="card-body" v-loading="wlanloading">
            <h5 class="card-title">{{ $t("setting.system.network.wlan") }}</h5>
            <el-form ref="form" label-width="80px">
              <el-form-item :label="$t('setting.system.network.wifi')">
                <el-select
                  v-model="wlandata.ssid"
                  :placeholder="$t('global.text.select')"
                >
                  <el-option
                    :label="$t('setting.system.network.noselect')"
                    value=""
                  >
                  </el-option>
                  <el-option
                    v-for="(item, index) in wifilist"
                    :key="index"
                    :label="item.ssid"
                    :value="item.ssid"
                  >
                    <span style="float: left">{{ item.ssid }}</span>
                    <span
                      style="float: right; color: #29ee2c; font-size: 13px"
                      v-if="item.signal >= 70"
                      >{{ item.signal }}%</span
                    >
                    <span
                      style="float: right; color: #ee8f29; font-size: 13px"
                      v-else-if="item.signal >= 50 && item.signal < 70"
                      >{{ item.signal }}%</span
                    >
                    <span
                      style="float: right; color: #db3737; font-size: 13px"
                      v-else-if="item.signal >= 30 && item.signal < 50"
                      >{{ item.signal }}%</span
                    >
                    <span
                      style="float: right; color: #8492a6; font-size: 13px"
                      v-else
                      >{{ item.signal }}%</span
                    >
                  </el-option>
                </el-select>
              </el-form-item>

              <el-form-item
                :label="$t('setting.system.network.password')"
                v-if="wlandata.ssid"
              >
                <el-input
                  v-model="wlandata.password"
                  type="password"
                  :placeholder="$t('global.text.inputpassword')"
                ></el-input>
              </el-form-item>
              <el-form-item>
                <el-button
                  type="primary"
                  v-if="wlandata.ssid && wlandata.password"
                  @click="savewlan"
                  >{{ $t("setting.system.network.connect") }}</el-button
                >
                <el-button @click="disconnectwlan" v-if="wlandata.ssid">{{
                  $t("setting.system.network.disconnect")
                }}</el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>
      </div>
      <div class="col-6">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">{{ $t("setting.system.network.dns") }}</h5>
            <el-form ref="form" label-width="80px">
              <el-form-item :label="$t('setting.system.network.maindns')">
                <el-input v-model="dnsinfo[0]"></el-input>
              </el-form-item>
              <el-form-item :label="$t('setting.system.network.backupdns')">
                <el-input v-model="dnsinfo[1]"></el-input>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" v-if="dnsinfo[0]" @click="savedns">{{
                  $t("setting.system.network.save")
                }}</el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>
      </div>
      <div class="col-6">
        <div class="card">
          <div class="card-body" v-loading="systemtypeloading">
            <h5 class="card-title">{{ $t("setting.system.softtype") }}</h5>
            <el-form ref="form" label-width="80px">
              <el-form-item :label="$t('setting.system.softtype')">
                <el-select
                  v-model="systeminfo.model"
                  :placeholder="$t('global.text.select')"
                >
                  <el-option
                    v-for="item in systemtypes"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>

              <el-form-item>
                <el-button type="primary" @click="changesystemmodel">{{
                  $t("setting.system.network.save")
                }}</el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>
      </div>

      <div class="col-6">
        <div class="card">
          <div class="card-body" v-loading="tcploading">
            <h5 class="card-title">{{ $t("setting.system.tcpserver") }}</h5>
            <el-form ref="form" label-width="80px">
              <el-form-item :label="$t('setting.system.tcpport')">
                <el-input
                  type="int"
                  min="4001"
                  max="39999"
                  v-model="tcpport"
                  :placeholder="$t('setting.system.tcpport')"
                ></el-input>
              </el-form-item>

              <el-form-item>
                <el-button type="primary" @click="savetcpport">{{
                  $t("setting.system.network.save")
                }}</el-button>
                <el-button @click="reboottcp">{{
                  $t("setting.system.restart")
                }}</el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <el-result
        icon="error"
        :title="$t('global.error')"
        :subTitle="$t('global.text.nopermission')"
      >
        <template slot="extra">
          <el-button type="primary" size="medium">返回</el-button>
        </template>
      </el-result>
    </div>
  </Layout>
</template>