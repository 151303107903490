<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";

/**
 * Starter page component
 */
export default {
  page() {
    return {
      title: this.$t("device.button.stockbind"),
      meta: [{ name: "description" }],
    };
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "Starter page",
      items: [
        {
          text: "Utility",
          href: "/",
        },
        {
          text: "Starter page",
          active: true,
        },
      ],

      ismobile: false,
      activeNames: [],
      activeapp: [],
      loading: true,
      systemtype: 0,
      stockbindlist: [],
      devicegroup1: [],
      devicegroup2: [],
    };
  },
  mounted() {
    this.title = this.$t("device.button.stockbind");

    this.items = [
      {
        text: this.$t("menuitems.devices.text"),
        href: "/",
      },
      {
        text: this.$t("device.button.stockbind"),
        active: true,
      },
    ];

    this.userinfo = JSON.parse(localStorage.getItem("user"));
    let flag = navigator.userAgent.match(
      /(phone|pod|iPhone|iPod|ios|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
    );
    if (flag) {
      this.ismobile = true;
    }
    this.getsysteminfo();
    this.getstockbind();
    this.getdeivelist();
  },
  methods: {
    getsysteminfo() {
      var that = this;
      that.$axios
        .post(
          that.apiuri,
          {
            action: "systeminfo",
          },
          {
            headers: { Openid: that.userinfo.openid },
          }
        )
        .then(function (response) {
          that.systemtype = parseInt(response.data.data.model);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    getstockbind() {
      var that = this;
      that.$axios
        .post(
          that.apiuri,
          {
            action: "getstockbind",
          },
          {
            headers: { Openid: that.userinfo.openid },
          }
        )
        .then(function (response) {
          that.loading = false;
          that.stockbindlist = response.data.data;
          for (let i = 0; i < that.stockbindlist.length; i++) {
            that.stockbindlist[i].edid = that.stockbindlist[i].edid.split(",");
            for(let j = 0; j < that.devicegroup1.length ; j++){
              if(that.devicegroup1[j].ED_id === that.stockbindlist[i].edid[0]){
                that.devicegroup2[j].disabled = true
              }
              if(that.devicegroup2[j].ED_id === that.stockbindlist[i].edid[1]){
                that.devicegroup1[j].disabled = true
              }
            }
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    getdeivelist() {
      var that = this;
      that.$axios
        .post(
          that.apiuri,
          {
            action: "getdevicelist",
          },
          {
            headers: { Openid: that.userinfo.openid },
          }
        )
        .then(function (response) {
          that.devicegroup1 = response.data.data;
          that.devicegroup2 = response.data.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    addstockbind() {
      var that = this;
      that.$axios
        .post(
          that.apiuri,
          {
            action: "addstockbind",
          },
          {
            headers: { Openid: that.userinfo.openid },
          }
        )
        .then(function (response) {
          that.getstockbind();
          if (response.data.status === 200) {
            that.$message({
              message: that.$t("global.success"),
              type: "success",
            });
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    changedeviceselect(idx,key){
      var that=this
      if(that.stockbindlist[idx].edid[key] > 0){
        if(key==1){
          if(that.stockbindlist[idx].edid[key] === that.stockbindlist[idx].edid[0]){
            that.$message.error(that.$t("device.stock.bindidentical"));
          }
        }
        if(key==0){
          if(that.stockbindlist[idx].edid[key] === that.stockbindlist[idx].edid[1]){
            that.$message.error(that.$t("device.stock.bindidentical"));
          }
        }
      }
      // console.log(idx,key)
      // let edid = that.stockbindlist[idx].edid[key]
      // if(key==0){
      //   for(let i=0;i<that.devicegroup2.length;i++){
      //     if(that.devicegroup2[i].ED_id === edid){
      //       that.devicegroup2[i].disabled = true
      //       that.devicegroup2[i].idx = idx
      //       that.devicegroup2[i].key = key
      //     }
      //   }
      // }
      // if(key==1){
      //   for(let i=0;i<that.devicegroup1.length;i++){
      //     if(that.devicegroup1[i].ED_id === edid){
      //       that.devicegroup1[i].disabled = true
      //       that.devicegroup1[i].idx = idx
      //       that.devicegroup1[i].key = key
      //     }
      //   }
      // }
    },
    saveitem(id,idx){
      var that=this
      that.$axios
        .post(
          that.apiuri,
          {
            action: "savestockbind",
            id: id,
            up: that.stockbindlist[idx].edid
          },
          {
            headers: { Openid: that.userinfo.openid },
          }
        )
        .then(function (response) {
          that.getstockbind();
          if (response.data.status === 200) {
            that.$message({
              message: that.$t("global.success"),
              type: "success",
            });
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    deleteitem(id){
      var that=this
      that.$axios
        .post(
          that.apiuri,
          {
            action: "delstockbind",
            id: id
          },
          {
            headers: { Openid: that.userinfo.openid },
          }
        )
        .then(function (response) {
          that.getstockbind();
          if (response.data.status === 200) {
            that.$message({
              message: that.$t("global.success"),
              type: "success",
            });
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body" v-if="systemtype == 0">
            <el-empty
              :description="$t('device.stock.bindnosupport')"
            ></el-empty>
          </div>
          <div class="card-body" v-loading="loading" v-else>
            <p>
              <el-button type="primary" round @click="addstockbind">{{
                $t("device.stock.addbind")
              }}</el-button>
            </p>
            <table class="table">
              <thead>
                <tr>
                  <th>{{ $t("device.stock.mainbinddevice") }}</th>
                  <th>{{ $t("device.stock.associateddevice") }}</th>
                  <th>{{ $t("device.stock.action") }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-if="!stockbindlist">
                  <td colspan="3">
                    <el-empty
                      :description="$t('device.stock.nobinddata')"
                    ></el-empty>
                  </td>
                </tr>
                <tr v-for="(val, idx) in stockbindlist" :key="idx">
                  <td>
                    <el-select v-model="val.edid[0]" filterable @change="changedeviceselect(idx,0)">
                      <el-option key="0" value="0" :label="$t('device.stock.noselect')"></el-option>
                      <el-option
                        v-for="item in devicegroup1"
                        :key="item.ED_id"
                        :label="item.name"
                        :value="item.ED_id"
                        :disabled="item.disabled"
                      >
                      </el-option>
                    </el-select>
                  </td>
                  <td>
                    <el-select v-model="val.edid[1]" filterable @change="changedeviceselect(idx,1)">
                      <el-option key="0" value="0" :label="$t('device.stock.noselect')"></el-option>
                      <el-option
                        v-for="item in devicegroup2"
                        :key="item.ED_id"
                        :label="item.name"
                        :value="item.ED_id"
                        :disabled="item.disabled"
                      >
                      </el-option>
                    </el-select>
                  </td>
                  <td>
                    <el-button type="success" plain @click="saveitem(val.id,idx)">{{$t("device.stock.save")}}</el-button>
                    <el-button type="danger" plain @click="deleteitem(val.id)">{{$t("device.stock.delete")}}</el-button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>