<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "app",
  page: {
    // All subcomponent titles will be injected into this template.
    titleTemplate(title) {
      title = typeof title === "function" ? title(this.$store) : title;
      return title ? `${title} | ${this.$t("title")}` : this.$t("title");
    },
  },
  created() {
    var that = this;
    if (localStorage.getItem("user")) {
      let userinfo = JSON.parse(localStorage.getItem("user"));
      that.initsocket()
    }
    
  },
  methods: {
    initsocket() {
      var that=this
      if ("WebSocket" in window) {
        that.ws = new WebSocket(that.wsuri);
        that.websocket.setWs(that.ws);
        that.ws.onopen = function () {
          that.$message({
            message: that.$t("global.ws.success"),
            type: "success",
          });
        };
        that.ws.onclose = function () {
          that.$message({
            message: that.$t("global.ws.warning"),
            type: "warning",
          });
          setTimeout(() => {
            that.initsocket();
          }, 2000);
        };
      } else {
        // 浏览器不支持 WebSocket
        that.$notify.error({
          title: $t("global.error"),
          message: $t("global.ws.browerror"),
          duration: 0,
        });
      }
    },
  },
};
</script>