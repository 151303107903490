import store from '@/state/store'

export default [
    {
        path: '/login',
        name: 'login',
        component: () => import('../views/pages/account/login'),
        meta: {
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                if (store.getters['auth/loggedIn']) {
                    // Redirect to the home page instead
                    next({ name: 'home' })
                } else {
                    // Continue to the login page
                    next()
                }
            },
        },
    },
    {
        path: '/register',
        name: 'register',
        component: () => import('../views/pages/account/register'),
        meta: {
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                if (store.getters['auth/loggedIn']) {
                    // Redirect to the home page instead
                    next({ name: 'home' })
                } else {
                    // Continue to the login page
                    next()
                }
            },
        },
    },
    {
        path: '/forgot-password',
        name: 'Forgot-password',
        component: () => import('../views/pages/account/forgot-password'),
        meta: {
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                if (store.getters['auth/loggedIn']) {
                    // Redirect to the home page instead
                    next({ name: 'home' })
                } else {
                    // Continue to the login page
                    next()
                }
            },
        },
    },
    {
        path: '/logout',
        name: 'logout',
        meta: {
            authRequired: true,
            beforeResolve(routeTo, routeFrom, next) {
                if (process.env.VUE_APP_DEFAULT_AUTH === "firebase") {
                    store.dispatch('auth/logOut')
                } else {
                    store.dispatch('authfack/logout')
                }
                const authRequiredOnPreviousRoute = routeFrom.matched.some(
                    (route) => route.push('/login')
                )
                // Navigate back to previous page, or home as a fallback
                next(authRequiredOnPreviousRoute ? { name: 'home' } : { ...routeFrom })
            },
        },
    },
    {
        path: '/',
        name: 'home',
        meta: {
            authRequired: true,
        },
        component: () => import('../views/pages/dashboard/index')
    },
    {
        path: '/device/list',
        name: "Device List",
        meta: { authRequired: true },
        component: () => import('../views/pages/devices/lists')
    },
    {
        path: '/device/devicedetail',
        name: "Device Detail",
        meta: { authRequired: true },
        component: () => import('../views/pages/devices/devicedetail')
    },
    {
        path: '/device/orders',
        name: "Order List",
        meta: { authRequired: true },
        component: () => import('../views/pages/devices/orders')
    },
    {
        path: '/device/orderdetail',
        name: "Order Detail",
        meta: { authRequired: true },
        component: () => import('../views/pages/devices/orderinfo')
    },
    {
        path: '/device/stock',
        name: "Device Stock",
        meta: { authRequired: true },
        component: () => import('../views/pages/devices/stock')
    },
    {
        path: '/device/stockbind',
        name: "Device Stock Binding",
        meta: { authRequired: true },
        component: () => import('../views/pages/devices/stockbind')
    },
    {
        path: '/device/ads',
        name: "Device AD",
        meta: { authRequired: true },
        component: () => import('../views/pages/devices/ads')
    },
    {
        path: '/device/hd',
        name: "Device HD",
        meta: { authRequired: true },
        component: () => import('../views/pages/devices/hd')
    },
    {
        path: '/device/console',
        name: "Console",
        meta: { authRequired: true },
        component: () => import('../views/pages/devices/console')
    },
    {
        path: '/device/grouporder',
        name: "Gourp Order",
        meta: { authRequired: true },
        component: () => import('../views/pages/devices/grouporder')
    },
    {
        path: '/device/baropen',
        name: "Bar Counter",
        meta: { authRequired: true },
        component: () => import('../views/pages/devices/baropen')
    },
    {
        path: '/device/barclose',
        name: "Bar Counter",
        meta: { authRequired: true },
        component: () => import('../views/pages/devices/barclose')
    },
    {
        path: '/iot/gateway',
        name: "IOT Gateway",
        meta: { authRequired: true },
        component: () => import('../views/pages/iot/gateway')
    },
    {
        path: '/iot/gatewaydetail',
        name: "Gateway Detail",
        meta: { authRequired: true },
        component: () => import('../views/pages/iot/gatewayinfo')
    },
    {
        path: '/iot/printer',
        name: "Printer",
        meta: { authRequired: true },
        component: () => import('../views/pages/iot/printer')
    },
    {
        path: '/iot/printerinfo',
        name: "Printer Info",
        meta: { authRequired: true },
        component: () => import('../views/pages/iot/printerinfo')
    },
    {
        path: '/iot/printertemplate',
        name: "Printer Template",
        meta: { authRequired: true },
        component: () => import('../views/pages/iot/printertemplate')
    },
    {
        path: '/app/my',
        name: 'My APPs',
        meta: { authRequired: true},
        component: () => import('../views/pages/app/my')
    },
    {
        path: '/app/shop',
        name: 'APPs',
        meta: { authRequired: true},
        component: () => import('../views/pages/app/shop')
    },
    {
        path: '/app/bill',
        name: 'APP Bill',
        meta: { authRequired: true},
        component: () => import('../views/pages/app/bill')
    },
    {
        path: '/wechat/mp',
        name: 'Wechat MP',
        meta: { authRequired: true},
        component: () => import('../views/pages/wechat/mp')
    },
    {
        path: '/wechat/micoapp',
        name: 'Wechat Micoapp',
        meta: { authRequired: true},
        component: () => import('../views/pages/wechat/micoapp')
    },
    {
        path: '/setting/account',
        name: 'Account Setting',
        meta: { authRequired: true},
        component: () => import('../views/pages/setting/account')
    },
    {
        path: '/setting/safty',
        name: 'Safty Setting',
        meta: { authRequired: true},
        component: () => import('../views/pages/setting/safty')
    },
    {
        path: '/setting/commonly',
        name: 'Commonly Setting',
        meta: { authRequired: true},
        component: () => import('../views/pages/setting/commonly')
    },
    {
        path: '/setting/system',
        name: 'System Setting',
        meta: { authRequired: true},
        component: () => import('../views/pages/setting/system')
    },
    {
        path: '/setting/hardware/bluetooth',
        name: 'Bluetooth Setting',
        meta: { authRequired: true},
        component: () => import('../views/pages/setting/hardware/bluetooth')
    },
    {
        path: '/sales/orders',
        name: 'Safty Setting',
        meta: { authRequired: true},
        component: () => import('../views/pages/sales/orders')
    },
    {
        path: '/sales/paysetting',
        name: 'Safty Setting',
        meta: { authRequired: true},
        component: () => import('../views/pages/sales/paysetting')
    },
    {
        path: '/sales/sharingmrg',
        name: 'Sharing Setting',
        meta: { authRequired: true},
        component: () => import('../views/pages/sales/sharing')
    },
    {
        path: '/goods/list',
        name: 'Goods List',
        meta: { authRequired: true},
        component: () => import('../views/pages/goods/list')
    },
    {
        path: '/goods/detail',
        name: 'Goods Detail',
        meta: { authRequired: true},
        component: () => import('../views/pages/goods/detail')
    },
    {
        path: '/goods/add',
        name: 'Goods Add',
        meta: { authRequired: true},
        component: () => import('../views/pages/goods/add')
    },
    {
        path: '/admin/list',
        name: 'Administrators List',
        meta: { authRequired: true},
        component: () => import('../views/pages/admin/list')
    },
    {
        path: '/admin/detail',
        name: 'Administrators Detail',
        meta: { authRequired: true},
        component: () => import('../views/pages/admin/detail')
    },
    {
        path: '/admin/add',
        name: 'Administrators Add',
        meta: { authRequired: true},
        component: () => import('../views/pages/admin/add')
    },
    {
        path: '/user/list',
        name: 'User List',
        meta: { authRequired: true},
        component: () => import('../views/pages/user/list')
    },
    {
        path: '/user/detail',
        name: 'User Detail',
        meta: { authRequired: true},
        component: () => import('../views/pages/user/detail')
    },
    {
        path: '/user/add',
        name: 'User Add',
        meta: { authRequired: true},
        component: () => import('../views/pages/user/add')
    },
    {
        path: '/user/group',
        name: 'User Group',
        meta: { authRequired: true},
        component: () => import('../views/pages/user/group')
    },
    {
        path: '/user/temporary',
        name: 'Temporary User',
        meta: { authRequired: true},
        component: () => import('../views/pages/user/temporary')
    },
    {
        path: '/system',
        name: 'System Info',
        meta: { authRequired: true},
        component: () => import('../views/pages/system/index')
    },
    {
        path: "/app/backup",
        name: "Backup",
        meta: { authRequired: true},
        component: () => import('../views/pages/extend/backup')
    },
    {
        path: "/app/remote",
        name: "Remote",
        meta: { authRequired: true},
        component: () => import('../views/pages/extend/remote')
    },
    {
        path: "/app/domain",
        name: "Domain",
        meta: { authRequired: true},
        component: () => import('../views/pages/extend/domain')
    },
    {
        path: "/about",
        name: "About",
        meta: { authRequired: true},
        component: () => import('../views/pages/extend/about')
    }
]
