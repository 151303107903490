<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";

/**
 * Starter page component
 */
export default {
  page() {
    return {
      title: this.title,
      meta: [{ name: "description" }],
    };
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "Starter page",
      items: [
        {
          text: "Utility",
          href: "/",
        },
        {
          text: "Starter page",
          active: true,
        },
      ],

      ismobile: false,
      activeNames: [],
      activeapp: [],
      showmodel: "1",
      userinfo: [],
      safeinfo: [],
      loading: true,
      goodslist: [],
    };
  },
  mounted() {
    this.title = this.$t("menuitems.goods.text");
    this.items = [
      {
        text: this.$t("menuitems.goods.text"),
        active: true,
      },
    ];

    this.userinfo = JSON.parse(localStorage.getItem("user"));
    let flag = navigator.userAgent.match(
      /(phone|pod|iPhone|iPod|ios|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
    );
    if (flag) {
      this.ismobile = true;
    }
    this.getgoodslist();
  },
  methods: {
    getgoodslist() {
      var that = this;
      that.$axios
        .post(
          that.apiuri,
          {
            action: "goodslist",
          },
          {
            headers: { Openid: that.userinfo.openid },
          }
        )
        .then(function (response) {
          that.loading = false;
          that.goodslist = response.data.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    deletegoods(id) {
      var that = this;
      that.$axios
        .post(
          that.apiuri,
          {
            action: "deletegoods",
            id: id
          },
          {
            headers: { Openid: that.userinfo.openid },
          }
        )
        .then(function (response) {
          that.$toast.clear();
          if (response.data.status == 200) {
            that.$message({
              message: that.$t("global.success"),
              type: "success",
            });
            that.getgoodslist()
          } else {
            that.$message.error(that.$t("global.delete.500"));
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body" v-loading="loading">
            <div class="col-sm-12 col-md-6">
              <p>
                <router-link :to="{ path: '/goods/add' }">
                  <a href="javascaript:;" class="btn btn-success">
                    <i class="ri-file-add-line"></i>
                    {{ $t("goods.add") }}
                  </a>
                </router-link>
              </p>
            </div>
            <table id="deviceslist_table" class="table table-striped">
              <thead>
                <tr>
                  <th class="sortStyle">{{ $t("goods.lists.title") }}</th>
                  <th class="sortStyle">{{ $t("goods.lists.description") }}</th>
                  <th class="sortStyle">{{ $t("device.lists.action") }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(val, idx) in goodslist" :key="idx">
                  <td>{{ val.title }}</td>
                  <td>{{ val.description }}</td>
                  <td>
                    <router-link
                      :to="{
                        path: '/goods/add',
                        query: { id: val.id },
                      }"
                    >
                      <a class="btn btn-info rounded-pill">
                        {{ $t("device.lists.info") }}
                      </a>
                    </router-link>
                    <button
                      type="button"
                      class="btn btn-danger rounded-pill"
                      @click="deletegoods(val.id)"
                    >
                      {{ $t("device.lists.delete") }}
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>