export const menuItems = [
    {
        id: 2,
        label: 'menuitems.dashboard.text',
        icon: 'ri-dashboard-line',
        badge: {
            variant: "success",
            text: "menuitems.dashboard.badge"
        },
        link: '/'
    },
    {
        id: 3,
        label: 'menuitems.devices.text',
        icon: 'ri-server-line',
        subItems: [
            {
                id: 300,
                label: 'menuitems.devices.lists',
                link: '/device/list'
            },
            {
                id: 308,
                label: 'device.button.stockbind',
                link: '/device/stockbind'
            }
        ]
    },
    {
        id: 301,
        label: 'menuitems.devices.orders',
        icon: 'ri-list-ordered',
        link: '/device/orders'
    },
    {
        id: 9,
        label: 'menuitems.goods.text',
        icon: 'ri-shopping-bag-2-line',
        link: '/goods/list'
    },
    {
        id: 10,
        label: 'menuitems.admin.text',
        icon: 'ri-admin-line',
        link: '/admin/list'
    },
    {
        id: 11,
        label: 'menuitems.user.text',
        icon: 'ri-file-user-line',
        link: '/user/list'
    },
    {
        id: 8,
        label: 'menuitems.setting.text',
        icon: 'ri-settings-line',
        subItems: [
            {
                id: 800,
                label: 'menuitems.setting.account',
                link: '/setting/account'
            },
            {
                id: 802,
                label: 'menuitems.setting.commonly',
                link: '/setting/commonly'
            },
            {
                id: 803,
                label: 'menuitems.setting.system',
                link: '/setting/system'
            },
            {
                id: 815,
                label: 'menuitems.setting.bluetooth',
                link: '/setting/hardware/bluetooth'
            }
        ]
    },
    {
        id: 6,
        label: 'menuitems.app.text',
        icon: 'ri-apps-line',
        subItems: [
            {
                id: 602,
                label: 'menuitems.app.bill',
                link: '/app/bill'
            },
            {
                id: 603,
                label: 'menuitems.app.backup',
                link: '/app/backup'
            },
            {
                id: 603,
                label: 'menuitems.app.remote',
                link: '/app/remote'
            },
            {
                id: 603,
                label: 'menuitems.app.domain',
                link: '/app/domain'
            },
            
        ]
    },
    {
        id: 12,
        label: 'system.text',
        icon: 'ri-file-info-line',
        link: '/system'
    },
    {
        id: 99,
        label: 'about.text',
        icon: 'ri-flag-line',
        link: '/about'
    },
    {
        id: 18,
        isLayout: true
    }
]