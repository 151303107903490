<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";

/**
 * Starter page component
 */
export default {
  page() {
    return {
      title: this.title,
      meta: [{ name: "description" }],
    };
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "Starter page",
      items: [
        {
          text: "Utility",
          href: "/",
        },
        {
          text: "Starter page",
          active: true,
        },
      ],
      adminlist: [],
      adminsearch: "",
      ismobile: false,
      activeNames: [],
      activeapp: [],
      showmodel: "1",
      userinfo: [],
      safeinfo: [],
      loading: true,
      block: false,
      systemtype: 0,
    };
  },
  mounted() {
    var that=this
    that.title = that.$t("menuitems.user.text");
    that.items = [
      {
        text: that.$t("menuitems.user.text"),
        active: true,
      },
    ];

    that.userinfo = JSON.parse(localStorage.getItem("user"));
    if (that.userinfo.topuid >= 1) {
      that.block = true;
    }
    let flag = navigator.userAgent.match(
      /(phone|pod|iPhone|iPod|ios|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
    );
    if (flag) {
      that.ismobile = true;
    }
    that.getadminlist();

    that.websocket.ws.onmessage = function (res) {
      let wsdata = JSON.parse(res.data);
      if (wsdata.action === "readcard") {
        if (that.cardhex) {
        } else {
          that.$axios
            .post(
              that.apiuri,
              {
                action: "finduser",
                hex: wsdata.uid,
              },
              {
                headers: { Openid: that.userinfo.openid },
              }
            )
            .then(function (response) {
              let res = response.data;
              if (res.status == 200) {
                that.$message({
                  message: that.$t("global.ws.finduser"),
                  type: "success",
                });
                that.$router.push({
                  path: "/user/detail",
                  query: { id: res.data.id },
                });
              } else {
                that.cardhex = wsdata.uid;
                if (!that.bindcardhex && that.bindcarduid) {
                  that
                    .$confirm(
                      that.$t("global.ws.userbindcard"),
                      that.$t("global.delete.sureyouaction"),
                      {
                        confirmButtonText: that.$t("global.button.ok"),
                        cancelButtonText: that.$t("global.button.cancel"),
                        type: "warning",
                      }
                    )
                    .then(() => {
                      that.$axios
                        .post(
                          that.apiuri,
                          {
                            action: "bindnewcard",
                            hex: that.cardhex,
                            uid: that.bindcarduid,
                          },
                          {
                            headers: { Openid: that.userinfo.openid },
                          }
                        )
                        .then(function (response) {
                          that.loading = false;
                          if (response.data.status == 200) {
                            that.$message({
                              message: that.$t("global.ws.bindsuccess"),
                              type: "success",
                            });
                            that.$router.push({
                              path: "/user/list",
                            });
                          } else if (response.data.status == 403) {
                            that.$message({
                              message: that.$t("global.delete.auth"),
                              type: "warning",
                            });
                          } else {
                            that.$message.error(that.$t("global.delete.500"));
                          }
                          that.getadminlist();
                        })
                        .catch(function (error) {
                          console.log(error);
                        });
                    })
                    .catch(() => {});
                } else {
                  that.$router.push({
                    path: "/user/add",
                    query: { id: wsdata.uid },
                  });
                }
              }
            })
            .catch(function (error) {
              console.log(error);
            });
        }
      }
      if (wsdata.action === "notice") {
        wsdata["time"] = Date.parse(new Date());
        wsdata["read"] = 0;
        that.noticelist.unshift(wsdata);
        localStorage.setItem("notice", JSON.stringify(that.noticelist));

        if (wsdata.type2 === "device") {
          that.$notify({
            title: that.$t("global.notice." + wsdata.status),
            message:
              that.$t("global.notice.device", { device: wsdata.edid }) +
              " " +
              that.$t("global.notice." + wsdata.type1),
            type: wsdata.status,
          });
        } else if (wsdata.type2 === "service") {
          that.$notify({
            title: that.$t("global.notice." + wsdata.status),
            message:
              that.$t("global.notice.service", {
                service: that.$t("global.notice.server." + wsdata.service),
              }) +
              " " +
              that.$t("global.notice." + wsdata.type1),
            type: wsdata.status,
          });
        }
      } else if (wsdata.type2 === "stock") {
        that.$notify({
          title: that.$t("global.notice." + wsdata.status),
          message: that.$t("global.notice.stock", {
            edid: wsdata.edid,
            stock: wsdata.stock,
          }),
          type: wsdata.status,
        });
      }
    };
    that.getsysteminfo()
  },
  methods: {
    getsysteminfo() {
      var that = this;
      that.$axios
        .post(
          that.apiuri,
          {
            action: "systeminfo",
          },
          {
            headers: { Openid: that.userinfo.openid },
          }
        )
        .then(function (response) {
          that.systemtype = parseInt(response.data.data.model);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    getadminlist() {
      var that = this;
      that.$axios
        .post(
          that.apiuri,
          {
            action: "userlist",
          },
          {
            headers: { Openid: that.userinfo.openid },
          }
        )
        .then(function (response) {
          that.loading = false;
          that.adminlist = response.data.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    deleteadmin(id) {
      var that = this;
      that
        .$confirm(
          that.$t("global.delete.deleteusertip"),
          that.$t("global.delete.sureyouaction"),
          {
            confirmButtonText: that.$t("global.button.ok"),
            cancelButtonText: that.$t("global.button.cancel"),
            type: "warning",
          }
        )
        .then(() => {
          that.loading = true;
          that.$axios
            .post(
              that.apiuri,
              {
                action: "delete",
                type: "user",
                id: id,
              },
              {
                headers: { Openid: that.userinfo.openid },
              }
            )
            .then(function (response) {
              that.loading = false;
              if (response.data.status == 200) {
                that.$message({
                  message: that.$t("global.delete.success"),
                  type: "success",
                });
              } else if (response.data.status == 403) {
                that.$message({
                  message: that.$t("global.delete.auth"),
                  type: "warning",
                });
              } else if (response.data.status == 404) {
                that.$message({
                  message: that.$t("device.orders.noorder"),
                  type: "warning",
                });
              } else {
                that.$message.error(that.$t("global.delete.500"));
              }
              that.getadminlist();
            })
            .catch(function (error) {
              console.log(error);
            });
        })
        .catch(() => {});
    },
    gettemporder(){
      var that=this
    }
  },
  computed: {
    searchinadminlist() {
      var datas = this.adminlist;
      if (this.adminsearch) {
        datas = datas.filter((value) => {
          return (
            !this.adminsearch || value.name.toLowerCase().includes(this.adminsearch.trim().toLowerCase())||
            !this.adminsearch || value.cardno.includes(this.adminsearch.trim())||
            !this.adminsearch || value.phone.includes(this.adminsearch.trim())
          );
        });
      }
      return datas;
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body" v-loading="loading">
            <div class="row col-md-12">
              <p>
                <router-link :to="{ path: '/user/add' }">
                  <el-button type="success" round>{{ $t("admin.add.text") }}</el-button>
                </router-link>
                <router-link :to="{ path: '/user/group' }" v-if="systemtype==2">
                  <el-button type="primary" round>{{ $t("admin.group.text") }}</el-button>
                </router-link>
                <router-link :to="{ path: '/user/temporary' }" v-if="systemtype==2">
                  <el-button type="info" round>{{ $t("admin.temporary.text") }}</el-button>
                </router-link>
              </p>
            </div>
            <div class="row col-md-12">
              <input
                type="search"
                v-model="adminsearch"
                class="form-control"
                :placeholder="$t('user.list.search')"
              />
            </div>
            <div class="row col-md-12">
              <table id="deviceslist_table" class="table table-striped">
                <thead>
                  <tr>
                    <th class="sortStyle">{{ $t("admin.lists.username") }}</th>
                    <th class="sortStyle">{{ $t("admin.lists.phone") }}</th>
                    <th class="sortStyle">{{ $t("user.list.membercard") }}</th>
                    <th class="sortStyle">{{ $t("admin.lists.status") }}</th>
                    <th class="sortStyle">{{ $t("device.lists.action") }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-if="!searchinadminlist || !adminlist">
                    <td colspan="7" align="center">
                      <el-empty
                        :description="$t('device.error.nodata')"
                      ></el-empty>
                    </td>
                  </tr>
                  <tr
                    v-else
                    v-for="val in searchinadminlist"
                    :class="'admin' + val.id"
                    :key="val.id"
                  >
                    <td>{{ val.name }}</td>
                    <td>{{ val.phone }}</td>
                    <td>{{ val.cardno }}</td>
                    <td>
                      <span v-if="val.status == 1" class="badge badge-success">
                        {{ $t("admin.lists.normal") }}
                      </span>
                      <span
                        v-else-if="val.status == 0"
                        class="badge badge-danger"
                      >
                        {{ $t("admin.lists.disable") }}
                      </span>
                    </td>
                    <td>
                      <router-link
                        :to="{ path: '/user/detail', query: { id: val.id } }"
                      >
                        <a class="btn btn-info rounded-pill">
                          {{ $t("user.list.info") }}
                        </a>
                      </router-link>
                      <router-link
                        :to="{ path: '/user/add', query: { id: val.id } }"
                      >
                        <a class="btn btn-info rounded-pill">
                          {{ $t("admin.lists.edit") }}
                        </a>
                      </router-link>

                      <button
                        type="button"
                        class="btn btn-danger rounded-pill"
                        @click="deleteadmin(val.id)"
                        v-if="!block"
                      >
                        {{ $t("device.lists.delete") }}
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          
        </div>
      </div>
    </div>
  </Layout>
</template>